import "./App.css";
import { Route, Routes, HashRouter } from "react-router-dom";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Login from "./pages/Login";
import MainLayout from "./components/layout/MainLayout";
import { getSuperCategory } from "./redux/superCategory/superCategorySlice";
import ListSuperCategory from "./pages/addEdits/superCategory/ListSuperCategory";
import AddSuperCategory from "./pages/addEdits/superCategory/AddSuperCategory";
import { getCategory } from "./redux/category/categorySlice";
import AddCategory from "./pages/addEdits/category/AddCategory";
import ListCategory from "./pages/addEdits/category/ListCategory";
import AddSubCategory from "./pages/addEdits/subcategory/AddSubCategory";
import { getSubCategory } from "./redux/subCategory/subCategorySlice";
import ListSubCategory from "./pages/addEdits/subcategory/ListSubCategory";
import AddBrand from "./pages/addEdits/brand/AddBrand";
import ListBrand from "./pages/addEdits/brand/ListBrand";
import AddProduct from "./pages/addEdits/product/AddProduct";
import { getBrand } from "./redux/brand/brandSlice";
import PdfTestPage from "./pages/addEdits/product/PdfTestPage";
import { getCategorytag } from "./redux/categoryTags/CategoryTagsSlice";
import AddCategoryTags from "./pages/addEdits/CategoryTags/AddCategoryTags";
import ListCategoryTags from "./pages/addEdits/CategoryTags/ListCategoryTags";
import FieldAdd from "./pages/addEdits/field/FieldAdd";
import FieldList from "./pages/addEdits/field/FieldList";
import AddFieldsStatus from "./pages/addEdits/fieldsStatus/AddFieldsStatus";
import { getField } from "./redux/field/FieldSlice";

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSuperCategory())
    dispatch(getCategory())
    dispatch(getSubCategory())
    dispatch(getBrand())
    dispatch(getCategorytag())
    dispatch(getField())
  }, [])


  return (
    <div className="wrapper">

      <HashRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin" exact element={<MainLayout />}>
            {/* <Route index element={<Dashboard />} /> */}
            {/* supercategory */}
            <Route
              path="/admin/addsupercategory"
              element={<AddSuperCategory />}
            />
            <Route
              path="/admin/listsupercategory"
              element={<ListSuperCategory />}
            />
            {/* category */}
            <Route path="/admin/addcategory" element={<AddCategory />} />
            <Route path="/admin/listcategory" element={<ListCategory />} />

            {/* subcategory */}
            <Route path="/admin/addsubcategory" element={<AddSubCategory />} />
            <Route
              path="/admin/listsubcategory"
              element={<ListSubCategory />}
            />

            {/* brand */}
            <Route path="/admin/addbrand" element={<AddBrand />} />
            <Route path="/admin/listbrand" element={<ListBrand />} />

            {/* product */}
            <Route path="/admin/add-product" element={<AddProduct />} />

            {/* category tags */}
            <Route path="/admin/add-category-tags" element={<AddCategoryTags />} />
            <Route path="/admin/list-category-tags" element={<ListCategoryTags />} />


            {/* Field Add */}
            <Route path="/admin/add-fields" element={<FieldAdd />} />
            <Route path="/admin/list-fields" element={<FieldList />} />

            {/* Field Add */}
            <Route path="/admin/add-fields-status" element={<AddFieldsStatus />} />

          </Route>
          {/* <Route path="*" element={<Error />} /> */}
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;