import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import {
  CategoryTagPost,
  CategoryTagImages,
  validateSlugUrl,
  removecatTagImage,
} from "../../../redux/categoryTags/CategoryTagsSlice";
import { useToasts } from "react-toast-notifications";

const AddCategoryTags = () => {
  const { mobileimage, desktopimage, isCatTagmobileImageLoading } = useSelector(
    (store) => store.categorytag
  );

  const { superCatShow } = useSelector((store) => store.superCategory);
  const { categoryShow } = useSelector((store) => store.category);
  const { subcategoryShow } = useSelector((store) => store.subCategories);

  const [names, setNames] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [catbySupercat, setCatbySupercat] = useState([]);
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [messurementType, setmessurementType] = useState("");

  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [supcategroyError, setsupcategroyError] = useState("");
  const [categroyError, setcategroyError] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [imageError, setimageError] = useState("");
  const [subcategroyError, setSubcategroyError] = useState("");
  const [messurementTypeError, setmessurementTypeError] = useState("");

  const { addToast } = useToasts();

  useEffect(() => {
    if (superCatShow.length === 1) {
      setSupercat(superCatShow[0].name);
      setSupercatid(superCatShow[0]._id);
    }
    if (categoryShow.length === 1) {
      setCategory(categoryShow[0].name);
      setCategoryid(categoryShow[0]._id);
    }
    if (subcategoryShow.length === 1) {
      setSubcategory(categoryShow[0].name);
      setSubcategoryid(categoryShow[0]._id);
    }
  }, [superCatShow, categoryShow, subcategoryShow]);

  useEffect(() => {
    if (buttonPress === true) {
      if (supercatid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (categoryid === "") {
        setcategroyError("Required");
      } else {
        setcategroyError("");
      }
      if (subcategoryid === "") {
        setSubcategroyError("Required");
      } else {
        setSubcategroyError("");
      }
      if (checkslugUrl === "") {
        setSlugUrlError("Required");
      } else {
        setSlugUrlError("");
      }
      if (mobileimage === "" && desktopimage === "") {
        setimageError("Required");
      } else {
        setimageError("");
      }

      if (supercat === "Clothing") {
        if (messurementType === "") {
          setmessurementTypeError("Required");
        } else {
          setmessurementTypeError("");
        }
      }
    }
  }, [
    buttonPress,
    supercatid,
    checkslugUrl,
    mobileimage,
    desktopimage,
    categoryid,
    subcategoryid,
    messurementType,
  ]);

  const config = {
    Headers: { "Content-Type": "application/json" },
  };

  const typeofMessurements = [
    { name: "Roman Type" },
    { name: "Regular Type" },
    { name: "Inner Type" },
    { name: "Sleeper Type" },
    { name: "Years Type" },
    { name: "Onesize" },
    { name: "Pack" },
  ];

  const verifyslugurl = async (e) => {
    setcategroyError("");
    if (categoryid) {
      const value = e.target.value;
      setNames(value);
      setMetatitle(value);
      setMetakeyword(value);
      setMetadesc(value);

      let newSlugUrl = `${category}-${value}`;
      newSlugUrl = newSlugUrl
        .trim()
        .toLowerCase()
        .replace(" ", "-")
        .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
        .replace("--", "-")
        .replace("---", "-")
        .replace("----", "-")
        .replace("/", "-")
        .replace("//", "-")
        .replace("///", "-");

      const subcatSlug = newSlugUrl
        .trim()
        .toLowerCase()
        .replace(" ", "-")
        .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
        .replace("--", "-")
        .replace("---", "-")
        .replace("----", "-")
        .replace("/", "-")
        .replace("//", "-")
        .replace("///", "-");
      setSlugUrl(subcatSlug);

      if (subcatSlug !== "") {
        let responce = await dispatch(validateSlugUrl(subcatSlug));
        if (responce.payload.success) {
          setError("Category Tag alredy exist");
          setSlugUrlError("Required");
          setErrorcolor("red");
        } else if (subcatSlug.length <= 2) {
          setErrorcolor("red");
          setError("minimum 3 letters");
          setSlugUrlError("Required");
          setCheckSlugUrl("");
        } else if (subcatSlug.length >= 3) {
          setErrorcolor("");
          setError("");
          setSlugUrlError("");
          setCheckSlugUrl(subcatSlug);
        }
      }
    } else {
      setcategroyError("Required");
      setError("Please Select Category");
    }
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);

    if (supercatid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (categoryid === "") {
      setcategroyError("Required");
    } else {
      setcategroyError("");
    }
    if (checkslugUrl === "") {
      setSlugUrlError("Required");
    } else {
      setSlugUrlError("");
    }
    if (mobileimage === "" && desktopimage === "") {
      setimageError("Required");
    } else {
      setimageError("");
    }

    if (subcategoryid === "") {
      setSubcategroyError("Required");
    } else {
      setSubcategroyError("");
    }
    if (supercat === "Clothing") {
      if (messurementType === "") {
        setmessurementTypeError("Required");
      } else {
        setmessurementTypeError("");
      }
    }

    if (
      checkslugUrl !== "" &&
      supercatid !== "" &&
      categoryid !== "" &&
      subcategoryid !== "" &&
      desktopimage !== "" &&
      mobileimage !== ""
    ) {
      if (supercat === "Clothing" && messurementType !== "") {
        const formData = {
          name: names.charAt(0).toUpperCase() + names.slice(1).toLowerCase(),
          slugUrl: checkslugUrl,
          superCategory: supercat,
          superCategoryId: supercatid,
          category: category,
          categoryId: categoryid,
          SubCategory: subcategory,
          SubCategoryId: subcategoryid,
          metaTitle: metatitle,
          metaKeyword: metakeyword,
          metaDesc: metadesc,
          mobileImage: mobileimage,
          desktopImage: desktopimage,
          messurementType: messurementType,
        };
        const data = await dispatch(CategoryTagPost(formData));

        if (data.payload.success === true) {
          addToast("Sub-Category Added", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
            placement: "bottom-right",
            style: { zIndex: 99999 },
          });

          if (superCatShow.length > 1) {
            setSupercatid("");
            setSupercat("");
          }
          if (categoryShow.length > 1) {
            setCategory("");
            setCategoryid("");
          }
          if (subcategoryShow.length > 1) {
            setSubcategory("");
            setSubcategoryid("");
          }
          setNames("");
          setSlugUrl("");
          setCheckSlugUrl("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setmessurementType("");
          setsupcategroyError("");
          setSlugUrlError("");
          setimageError("");
          setbuttonPress(false);
          setmessurementTypeError("");
          dispatch(removecatTagImage());
        } else {
          setbuttonLoading(false);
          setSlugUrlError("Required");
          addToast(`${data.payload.massage}`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 4000,
            placement: "bottom-right",
            style: { zIndex: 99999 },
          });
        }
      } else {
        const formData = {
          name: names.charAt(0).toUpperCase() + names.slice(1).toLowerCase(),
          slugUrl: checkslugUrl,
          superCategory: supercat,
          superCategoryId: supercatid,
          category: category,
          categoryId: categoryid,
          SubCategory: subcategory,
          SubCategoryId: subcategoryid,
          metaTitle: metatitle,
          metaKeyword: metakeyword,
          metaDesc: metadesc,
          mobileImage: mobileimage,
          desktopImage: desktopimage,
        };
        const data = await dispatch(CategoryTagPost(formData));

        if (data.payload.success === true) {
          addToast("Sub-Category Added", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
            placement: "bottom-right",
            style: { zIndex: 99999 },
          });

          if (superCatShow.length > 1) {
            setSupercatid("");
            setSupercat("");
          }
          if (categoryShow.length > 1) {
            setCategory("");
            setCategory("");
          }
          if (subcategoryShow.length > 1) {
            setSubcategory("");
            setSubcategoryid("");
          }
          setNames("");
          setSlugUrl("");
          setCheckSlugUrl("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setsupcategroyError("");
          setSlugUrlError("");
          setimageError("");
          setbuttonPress(false);
          dispatch(removecatTagImage());
        } else {
          setbuttonLoading(false);
          setSlugUrlError("Required");
          addToast(`${data.payload.massage}`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 4000,
            placement: "bottom-right",
            style: { zIndex: 99999 },
          });
        }
      }
    } else {
      setbuttonLoading(false);
    }
  };

  const selectsuperCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercatid(value);
    setSupercat(text);
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };
  useEffect(() => {
    const catbysupercat = categoryShow.filter(
      (cat) => cat.superCategoryId === supercatid
    );
    setCatbySupercat(catbysupercat);
  }, [supercatid, categoryShow]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };
  const selectMessurement = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setmessurementType(value);
  };
  useEffect(() => {
    const subcatbycat = subcategoryShow.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategoryShow]);

  const dispatch = useDispatch();

  const imagesChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(CategoryTagImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Category-Tag</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    {superCatShow.length > 1 && (
                      <Col md>
                        <Form.Label htmlFor="disabledTextInput">
                          Super Category Name
                        </Form.Label>

                        <Form.Select
                          aria-label="Floating label select example"
                          onChange={(e) => {
                            selectsuperCategory(e);
                          }}
                          value={superCatShow._id}
                          name={superCatShow.name}
                          className={
                            supcategroyError !== "" ? "error_class" : ""
                          }
                        >
                          <option hidden selected>
                            {"Select Super Category"}
                          </option>
                          {superCatShow.map((data) => (
                            <option
                              key={data._id}
                              value={data._id}
                              name={data.name}
                              selected={supercatid === data._id}
                            >
                              {data.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    )}
                    {categoryShow.length > 1 && (
                      <Col md>
                        <Form.Label htmlFor="disabledTextInput">
                          Category Name
                        </Form.Label>

                        <Form.Select
                          aria-label="Floating label select example"
                          onChange={(e) => {
                            selectCategory(e);
                          }}
                          value={catbySupercat._id}
                          name={catbySupercat.name}
                          className={categroyError !== "" ? "error_class" : ""}
                        >
                          <option hidden selected>
                            {"Select Category"}
                          </option>
                          {catbySupercat.map((data) => (
                            <option
                              key={data._id}
                              value={data._id}
                              name={data.name}
                              selected={categoryid === data._id}
                            >
                              {data.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    )}
                  </Row>
                  <Row className="g-2  m-2 ">
                    {subcategoryShow.length > 1 && (
                      <Col md>
                        <Form.Label htmlFor="disabledTextInput">
                          Sub Category<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Floating label select example"
                          onChange={(e) => {
                            selectSubCategory(e);
                          }}
                          value={subCat._id}
                          name={subCat.name}
                          className={
                            subcategroyError !== "" ? "error_class" : ""
                          }
                        >
                          <option hidden selected>
                            {"Select Sub Category"}
                          </option>
                          {subCat.map((data) => (
                            <option
                              key={data._id}
                              value={data._id}
                              name={data.name}
                              selected={subcategoryid === data._id}
                            >
                              {data.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    )}
                    {supercat === "Clothing" && (
                      <Col md>
                        <Form.Label htmlFor="disabledTextInput">
                          Messurement<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Floating label select example"
                          onChange={(e) => {
                            selectMessurement(e);
                          }}
                          value={typeofMessurements.name}
                          name={typeofMessurements.name}
                          className={
                            messurementTypeError !== "" ? "error_class" : ""
                          }
                        >
                          <option hidden selected>
                            {"Select Messurement"}
                          </option>
                          {typeofMessurements.map((data) => (
                            <option
                              key={data.name}
                              value={data.name}
                              name={data.name}
                              selected={messurementType === data.name}
                            >
                              {data.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    )}
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={names}
                        onChange={(e) => verifyslugurl(e)}
                        className={slugUrlError !== "" ? "error_class" : ""}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Sub-Category Images</Form.Label>
                      <Form.Control
                        type="file"
                        className={imageError !== "" ? "error_class" : ""}
                        onChange={imagesChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 291px * 200px
                      </p>
                      <div>
                        {isCatTagmobileImageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={desktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col>
                      <Form.Label>Meta Title *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Keyword *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Descriptrion *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="card-footer ">
                  {buttonLoading ? (
                    <button className="btn btn-primary">
                      Add Category Tag
                    </button>
                  ) : (
                    <button className="btn btn-primary" type="submit">
                      Add Category Tag
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddCategoryTags;
