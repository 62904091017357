import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import {
  postSuperCategory,
  validateSuperCatSlugUrl,
} from "../../../redux/superCategory/superCategorySlice";
import { useToasts } from "react-toast-notifications";

const AddSuperCategory = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [names, setNames] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setNames(value);
    setErrorcolor("green");
    setCheckSlugUrl("");
    setError("category available");
    const catSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(catSlug);

    if (catSlug !== "") {
      let responce = await dispatch(validateSuperCatSlugUrl(catSlug));
      if (responce.payload.success) {
        setError("super category alredy exist");
        setErrorcolor("red");
      } else if (catSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (catSlug.length >= 3) {
        setErrorcolor("green");
        setError("super category available");
        setCheckSlugUrl(catSlug);
      }
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (checkslugUrl !== "") {
      const formData = {
        name: names.charAt(0).toUpperCase() + names.slice(1).toLowerCase(),
        slugUrl: checkslugUrl,
        metaTitle: metatitle,
        metaKeyword: metakeyword,
        metaDesc: metadesc,
      };
      const data = await dispatch(postSuperCategory(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          addToast("Supercategory Added", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 6000,
            placement: "bottom-right",
            style: { zIndex: 99999 },
          });
          setNames("");
          setSlugUrl("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setError("");
        } else {
          addToast(`${data.message}`, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 6000,
            placement: "bottom-right",
            style: { zIndex: 99999 },
          });
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Add Super Category</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Super Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter name"
                        value={names}
                        onChange={verifyslugurl}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col>
                      <Form.Label>Meta Title *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Keyword *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Descriptrion *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {checkslugUrl !== "" ? (
                    <button className="btn btn-primary" type="submit">
                      Add Super Category
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Super Category
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddSuperCategory;
