import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import {
  categoryDelete,
  categoryUpdate,
} from "../../../redux/category/categorySlice";
import { useEffect } from "react";
import { Modal, ModalHeader } from "reactstrap";

const ListCategory = () => {
  const dispatch = useDispatch();

  const { categorytotal, categoryLoading } = useSelector(
    (store) => store.category
  );

  const { superCatTotal } = useSelector((store) => store.superCategory);

  const [catbysuper, setCatbysuper] = useState([]);
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [priority, setPriority] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercategoryid(value);
    setSupercategory(text);
  };

  useEffect(() => {
    if (supercategoryid === "") {
      let sortCat = [...categorytotal].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      sortCat = sortCat.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setCatbysuper(sortCat);
    } else {
      const catbysupercat = categorytotal.filter(
        (cat) => cat.superCategoryId === supercategoryid
      );
      let sortCat = [...catbysupercat].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      sortCat = sortCat.map((row, index) => ({
        ...row,
        index: index + 1,
      }));

      setCatbysuper(sortCat);
    }
  }, [supercategoryid, categorytotal]);

  const cancelClick = (e, category) => {
    setCancelModel(true);
    setSelectCategory(category);
  };

  const deleteClick = async (e, id) => {
    const data = await dispatch(categoryDelete(selectCategory._id));
    setCancelModel(false);
  };

  const statusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        catid: data._id,
        status: value,
      };
      const updateCat = await dispatch(categoryUpdate(formData));
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "S. No",
      align: "center",
      headerAlign: "center",
      filterable: false,
    },
    {
      field: "name",
      headerName: "CATEGORY NAME",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "thumbnail",
    //   headerName: "IMAGE",
    //   width: 230,
    //   headerAlign: "center",

    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <img
    //           className="d-flex m-auto"
    //           src={params.row.thumbnail}
    //           width={100}
    //           alt="thumnail"
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      field: "STATUS",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.status}
                  checked={params.row.status}
                  onChange={(e) => statusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },

    {
      field: "priority",
      headerName: "PRIORITY",
      width: 150,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "ACTION",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
            <Link to={`/admin/updatecategory/${params.row.slugUrl}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },

    {
      field: "ACTION.",
      width: 200,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              onClick={(e) => cancelClick(e, cellValues.row)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div className="card-body">
              <Row className="g-2  m-2 w-50">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">
                    Super Category
                  </Form.Label>
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => {
                      selectsupCategory(e);
                    }}
                    value={superCatTotal._id}
                    name={superCatTotal.name}
                  >
                    <option hidden selected>
                      {"Select Super Category"}
                    </option>
                    {superCatTotal.map((data) => (
                      <option
                        key={data._id}
                        value={data._id}
                        name={data.name}
                        required
                      >
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </div>
            <div
              style={{ height: 650, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={catbysuper}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={10}
                rowsPerPageOptions={[10]}
                // checkboxSelection
              />
            </div>
          </div>
          <Modal
            size="md"
            isOpen={cancelmodel}
            toggle={() => setCancelModel(!cancelmodel)}
          >
            <ModalHeader>
              <div className=" ">
                <div className="col-12 ">
                  <div className="col-12">
                    <h3>
                      Do You Want to{" "}
                      <span style={{ color: "red" }}>Delete</span>{" "}
                      {selectCategory !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#dc3545",
                            }}
                          >
                            {selectCategory.name}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                      Category
                    </h3>
                    <div className="col-12 mt-3 ">
                      <div className="col-6 d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="error"
                          className="mx-1 px-5"
                          onClick={() => setCancelModel(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mx-1 px-5"
                          onClick={(e) => deleteClick(e)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalHeader>
          </Modal>
        </div>
      </Fragment>
    </>
  );
};

export default ListCategory;
