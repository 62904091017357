import React, { useState } from "react";
import { PDFDocument, PDFName, PDFDict } from "pdf-lib";
import pako from "pako";
import { saveAs } from "file-saver";

const PdfTestPage = () => {
  const [file, setFile] = useState(null);

  const compressPDF = async (file) => {
    const pdfBytes = await file.arrayBuffer();
    const compressedBytes = pako.deflate(pdfBytes);
    return compressedBytes;
  };

  // Function to save the compressed PDF
  const saveCompressedPDF = async (compressedPDF) => {
    console.log(compressedPDF, "compressedBytes");
    if (compressedPDF) {
      const blob = new Blob([compressedPDF], { type: "application/pdf" });
      saveAs(blob, "compressed_pdf.pdf");
    }
  };

  // Example usage
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // try {
    //   const compressedBytes = await compressPDF(file);
    //   await saveCompressedPDF(compressedBytes);
    //   alert("PDF compressed successfully!");
    // } catch (error) {
    //   console.error("Error compressing PDF:", error);
    //   alert("Error compressing PDF. Please try again.");
    // }

    try {
      const pdfDoc = await PDFDocument.load(file);
      const pages = pdfDoc.getPages();
      pages.forEach((page) => {
        page.scale(0.5);
      });
      const compressedPDFBytes = await pdfDoc.save();
      if (compressedPDFBytes) {
        const blob = new Blob([compressedPDFBytes], {
          type: "application/pdf",
        });
        saveAs(blob, "compressed_pdf.pdf");
      }
    } catch (error) {
      console.error("Error compressing PDF:", error);
    }
  };

  return (
    <div>
      <div>
        <input type="file" onChange={handleFileUpload} />
        <input type="file" onChange={handleFileUpload} />
      </div>
    </div>
  );
};

export default PdfTestPage;
