import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { categoryPost } from "../../../redux/category/categorySlice";
import { useToasts } from "react-toast-notifications";
import { PostField } from "../../../redux/field/FieldSlice";

const FieldAdd = () => {
  const { superCatTotal } = useSelector((store) => store.superCategory);

  const { addToast } = useToasts();

  const [supcategroyError, setsupcategroyError] = useState("");
  const [categroy, setCategroy] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [imageError, setimageError] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [result, setResult] = useState([]);
  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (buttonPress === true) {
      if (supercatid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (checkslugUrl === "") {
        setSlugUrlError("Required");
      } else {
        setSlugUrlError("");
      }
    }
  }, [buttonPress, supercatid, checkslugUrl]);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setCategroy(value);
    setCheckSlugUrl(value);
    setSlugUrl(value);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);

    if (supercatid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (checkslugUrl === "") {
      setSlugUrlError("Required");
    } else {
      setSlugUrlError("");
    }

    if (checkslugUrl !== "" && supercatid !== "") {
      const formData = {
        name: checkslugUrl,
        superCategoryId: supercatid,
        superCategory: supercat,
      };

      console.log(formData, "formData");
      const data = await dispatch(PostField(formData));
      if (data.payload.success) {
        addToast("Field Added", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
          placement: "bottom-right",
          style: { zIndex: 99999 },
        });
        setCategroy("");
        setSlugUrl("");
        setCheckSlugUrl("");
        setSupercat("");
        setSupercatid("");
        setsupcategroyError("");
        setSlugUrlError("");
        setbuttonPress(false);
      } else {
        setbuttonLoading(false);
      }
    } else {
      setbuttonLoading(false);
    }
  };

  const selectSuperCat = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercatid(value);
    setSupercat(text);
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Field</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category
                      </Form.Label>

                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSuperCat(e);
                        }}
                        value={superCatTotal._id}
                        name={superCatTotal.name}
                        className={supcategroyError !== "" ? "error_class" : ""}
                      >
                        <option selected hidden>
                          {"Select Super Category"}
                        </option>
                        {superCatTotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            selected={supercatid === data._id}
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Field Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter Category"
                        value={categroy}
                        onChange={(e) => verifyslugurl(e)}
                        className={slugUrlError !== "" ? "error_class" : ""}
                        style={{ textTransform: "capitalize" }}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {buttonLoading ? (
                    <button className="btn btn-primary">Add Field</button>
                  ) : (
                    <button className="btn btn-primary" type="submit">
                      Add Field
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default FieldAdd;
