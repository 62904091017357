import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
    fieldtotal: localStorage.getItem("fieldtotal")
        ? JSON.parse(localStorage.getItem("fieldtotal")).sort((a, b) =>
            a.name > b.name ? 1 : -1
        )
        : [],
    fieldShow: localStorage.getItem("fieldtotal")
        ? JSON.parse(localStorage.getItem("fieldtotal")).filter((data) => data.show === true).sort((a, b) =>
            a.name > b.name ? 1 : -1
        )
        : [],
    isLoading: true,
    fieldLoading: true,
    delfieldLoading: true,
};

export const getField = createAsyncThunk("field/getField", async (thunkAPI) => {
    try {
        const url = `${Baseurl}/api/v1/field/all`;
        const resp = await axios(url);
        return resp.data.fields;
    } catch (error) {
        return thunkAPI.rejectWithValue("404 Not Found");
    }
});

export const PostField = createAsyncThunk(
    "field/PostField",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
                maxBodyLength: Infinity,
            };
            const url = `${Baseurl}/api/v1/field/new`;
            const resp = await axios.post(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("field Not create");
        }
    }
);

export const FieldUpdate = createAsyncThunk(
    "field/FieldUpdate",
    async (formData, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
                maxBodyLength: Infinity,
            };
            const url = `${Baseurl}/api/v1/field/${formData.fieldId}`;
            const resp = await axios.put(url, formData, config);
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue("field Not create");
        }
    }
);

export const FieldDelete = createAsyncThunk(
    "field/FieldDelete",
    async (id, thunkAPI) => {
        try {
            const config = {
                Headers: { "Content-Type": "application/json" },
            };
            const url = `${Baseurl}/api/v1/field/${id}`;
            const resp = await axios.delete(url, id, config);
            const myreturn = {
                success: resp.data.success,
                id: id,
            };
            return myreturn;
        } catch (error) {
            return thunkAPI.rejectWithValue("field Not create");
        }
    }
);

const FieldSlice = createSlice({
    name: "field",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getField.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getField.fulfilled, (state, action) => {
                state.fieldtotal = action.payload.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                );
                state.fieldShow = state.fieldtotal.filter((data) => data.show === true);
                localStorage.setItem("fieldtotal", JSON.stringify(state.fieldtotal));
                state.isLoading = false;
                state.fieldLoading = false;
            })
            .addCase(getField.rejected, (state) => {
                state.isLoading = true;
            });
        builder
            .addCase(PostField.pending, (state) => {
                state.fieldLoading = true;
            })
            .addCase(PostField.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.fieldtotal = [...state.fieldtotal, action.payload.field].sort((a, b) =>
                        a.name > b.name ? 1 : -1
                    );
                    state.fieldShow = state.fieldtotal.filter((data) => data.show === true);
                    localStorage.setItem("fieldtotal", JSON.stringify(state.fieldtotal));
                }
                state.fieldLoading = false;
            })
            .addCase(PostField.rejected, (state) => {
                state.fieldLoading = true;
            });

        builder
            .addCase(FieldUpdate.pending, (state) => {
                state.fieldLoading = true;
            })
            .addCase(FieldUpdate.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.fieldtotal = state.fieldtotal.filter(
                        (brand) => brand._id !== action.payload.brand._id
                    );
                    state.fieldtotal = [...state.fieldtotal, action.payload.field];
                    state.fieldShow = state.fieldtotal.filter((data) => data.show === true);
                    localStorage.setItem("fieldtotal", JSON.stringify(state.fieldtotal));
                }
                state.fieldLoading = false;
            })
            .addCase(FieldUpdate.rejected, (state) => {
                state.fieldLoading = true;
            });
        builder
            .addCase(FieldDelete.pending, (state) => {
                state.delfieldLoading = true;
            })
            .addCase(FieldDelete.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.fieldtotal = state.fieldtotal.filter(
                        (brand) => brand._id !== action.payload.id
                    ).sort((a, b) =>
                        a.name > b.name ? 1 : -1
                    );
                    state.fieldShow = state.fieldtotal.filter((data) => data.show === true);
                    localStorage.setItem("fieldtotal", JSON.stringify(state.fieldtotal));
                }
                state.delfieldLoading = false;
            })
            .addCase(FieldDelete.rejected, (state) => {
                state.delfieldLoading = true;
            });
    },

});

export const {
} = FieldSlice.actions;
export default FieldSlice.reducer;