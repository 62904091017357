import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineLibraryAdd } from "react-icons/md";

import { FaHome } from "react-icons/fa";
import { BiCategoryAlt } from "react-icons/bi";
import { RiUserSettingsFill } from "react-icons/ri";
import { TbBrandReactNative } from "react-icons/tb";
import logo from "../img/logo.jpg";

const Menu = () => {
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/admin" className="brand-link">
          <img
            src={logo}
            alt="Admin"
            className="brand-image img-circle elevation-3 "
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">Super Admin</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          <nav className="mt-2">
            {/*================================ Dashboard ==========================*/}
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item has-treeview">
                <Link to="/admin" className="nav-link">
                  <span>
                    <FaHome />
                  </span>
                  <p>Dashboard</p>
                </Link>
              </li>
              {/*============================ product Setting Start ================================*/}

              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <span>
                    <RiUserSettingsFill />
                  </span>
                  <p>
                    Add-Edit
                    <i className="right fas fa-angle-down arrowsize"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Super Category
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/addsupercategory" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Super Category</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          to="/admin/listsupercategory"
                          className="nav-link"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Super Category</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Category
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/addcategory" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add category</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/listcategory" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List category</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Sub Category
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/addsubcategory" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Sub-category</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/listsubcategory" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Sub-category</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Brand
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addbrand">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Brand</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/listbrand">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Brand</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Product
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/add-product">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Product</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/listbrand">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Brand</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Category Tags
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/add-category-tags">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Category Tags</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/list-category-tags">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Category Tags</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Fields
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/add-fields">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Fields</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/list-fields">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Fields</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Fields Status
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/add-fields-status">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Fields Status</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/list-fields">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Fields</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/*============================ product Setting end ================================*/}

            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};
export default Menu;
