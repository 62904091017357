import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import {
  CategoryTagDelete,
  CategoryTagUpdate,
} from "../../../redux/categoryTags/CategoryTagsSlice";
import { Modal, ModalHeader } from "reactstrap";

const ListCategoryTags = () => {
  const dispatch = useDispatch();

  const { categoryTagTotal } = useSelector((store) => store.categorytag);
  const { categorytotal } = useSelector((store) => store.category);
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { subcategoryShow } = useSelector((store) => store.subCategories);

  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");

  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");

  const [catbySupercat, setCatbySupercat] = useState([]);
  const [subcatbyCat, setSubcatbyCat] = useState([]);
  const [subcatbyCattags, setSubcatbyCattags] = useState([]);
  const [subcat, setsubcat] = useState([]);
  const [selectCategorys, setSelectCategory] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercategoryid(value);
    setSupercategory(text);
  };

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
    setSubcategoryid("");
    setSubcategory("");
  };
  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbySupercat(catbysupercat);
  }, [supercategoryid, categorytotal]);

  useEffect(() => {
    if (categoryid === "" && subcategoryid !== "") {
      let slotFilter = [...categoryTagTotal].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      slotFilter = slotFilter.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setSubcatbyCat(slotFilter);
    } else {
      const catbysupercat = subcategoryShow.filter(
        (cat) => cat.categoryId === categoryid
      );
      setsubcat(catbysupercat);
      const subcatbycat = categoryTagTotal.filter(
        (cat) => cat.categoryId === categoryid
      );
      let slotFilter = [...subcatbycat].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      slotFilter = slotFilter.map((row, index) => ({
        ...row,
        index: index + 1,
      }));

      setSubcatbyCat(slotFilter);
    }
  }, [categoryid, categoryTagTotal, subcategoryShow, subcategoryid]);

  useEffect(() => {
    if (subcategoryid === "") {
      let slotFilter = subcatbyCat;
      setSubcatbyCat(slotFilter);
    } else {
      const subcatbycat = categoryTagTotal.filter(
        (cat) => cat.SubCategoryId === subcategoryid
      );
      let slotFilter = [...subcatbycat].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      slotFilter = slotFilter.map((row, index) => ({
        ...row,
        index: index + 1,
      }));

      setSubcatbyCat(slotFilter);
    }
  }, [subcategoryid, subcatbyCat, categoryTagTotal]);

  const deleteClick = async (e, id) => {
    const data = await dispatch(CategoryTagDelete(selectCategorys.id));
    setCancelModel(false);
  };

  const cancelClick = (e, subcategory) => {
    setCancelModel(true);
    setSelectCategory(subcategory);
  };

  const statusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        cattagid: data._id,
        status: value,
      };
      const updateCat = await dispatch(CategoryTagUpdate(formData));
    }
  };
  const columns = [
    {
      field: "index",
      headerName: "S. No",
      align: "center",
      headerAlign: "center",
      filterable: false,
    },
    {
      field: "name",
      headerName: "SUB-CATEGORY NAME",
      width: 200,
      // align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "thumbnail",
    //   headerName: "IMAGE",
    //   width: 230,
    //   headerAlign: "center",

    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <img
    //           className="d-flex m-auto"
    //           src={params.row.thumbnail}
    //           width={100}
    //           alt="thumnail"
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      field: "STATUS",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.status}
                  checked={params.row.status}
                  onChange={(e) => statusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },

    {
      field: "priority",
      headerName: "PRIORITY",
      width: 150,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "ACTION",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
            <Link to={`/admin/updatesubcategory/${params.row.slugUrl}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 200,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto my-2">
            <Button
              variant="contained"
              onClick={(e) => cancelClick(e, cellValues.row)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div className="card-body">
              <Row className="g-2  m-2">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">
                    Super Category
                  </Form.Label>
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => {
                      selectsupCategory(e);
                    }}
                    value={superCatTotal._id}
                    name={superCatTotal.name}
                  >
                    <option hidden selected>
                      {"Select Super Category"}
                    </option>
                    {superCatTotal.map((data) => (
                      <option
                        key={data._id}
                        value={data._id}
                        name={data.name}
                        required
                      >
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">Category</Form.Label>
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => {
                      selectCategory(e);
                    }}
                    value={catbySupercat._id}
                    name={catbySupercat.name}
                  >
                    <option hidden selected>
                      {"Select Category"}
                    </option>
                    {catbySupercat.map((data) => (
                      <option
                        key={data._id}
                        value={data._id}
                        name={data.name}
                        required
                      >
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">
                    Sub Category
                  </Form.Label>
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => {
                      selectSubCategory(e);
                    }}
                    value={subcat._id}
                    name={subcat.name}
                  >
                    <option hidden selected>
                      {"Select Sub Category"}
                    </option>
                    {subcat.map((data) => (
                      <option
                        key={data._id}
                        value={data._id}
                        name={data.name}
                        required
                      >
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </div>
            <div
              style={{ height: 650, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={subcatbyCat}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={10}
                rowHeight={100}
                getRowHeight={() => "auto"}
                rowsPerPageOptions={[10]}
                // checkboxSelection
              />
            </div>
          </div>

          <Modal
            size="md"
            isOpen={cancelmodel}
            toggle={() => setCancelModel(!cancelmodel)}
          >
            <ModalHeader>
              <div className=" ">
                <div className="col-12 ">
                  <div className="col-12">
                    <h3>
                      Do You Want to{" "}
                      <span style={{ color: "red" }}>Delete</span>{" "}
                      {selectCategorys !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#dc3545",
                            }}
                          >
                            {selectCategorys.name}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                      Sub-Category
                    </h3>
                    <div className="col-12 mt-3 ">
                      <div className="col-6 d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="error"
                          className="mx-1 px-5"
                          onClick={() => setCancelModel(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mx-1 px-5"
                          onClick={(e) => deleteClick(e)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalHeader>
          </Modal>
        </div>
      </Fragment>
    </>
  );
};

export default ListCategoryTags;
