import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    romanTypeMessurements: [
        { label: "ONESIZE" },
        { label: "PACK" },
        { label: "3XS" },
        { label: "XXS" },
        { label: "XS" },
        { label: "XS/S" },
        { label: "S" },
        { label: "XS/M" },
        { label: "S/M" },
        { label: "M" },
        { label: "M/L" },
        { label: "L" },
        { label: "S/L" },
        { label: "L/XL" },
        { label: "XL" },
        { label: "XL/XXL" },
        { label: "XXL" },
        { label: "3XL" },
        { label: "4XL" },
        { label: "4XL/5XL" },
        { label: "5XL" },
        { label: "6XL" },
        { label: "7XL" },
        { label: "8XL" },
        { label: "9XL" },
        { label: "10XL" },

    ],

    yearsTypeMessurements: [
        { label: "ONESIZE" },
        { label: "PACK" },
        { label: "1-2Y" },
        { label: "2-3Y" },
        { label: "3-4Y" },
        { label: "4-5Y" },
        { label: "5-6Y" },
        { label: "6-7Y" },
        { label: "7-8Y" },
        { label: "8-9Y" },
        { label: "9-10Y" },
        { label: "10-11Y" },
        { label: "11-12Y" },
        { label: "12-13Y" },
        { label: "13-14Y" },
        { label: "14-15Y" },
        { label: "15-16Y" }
    ],
    RegularTypeMessurements: [
        { label: "ONESIZE" },
        { label: "PACK" },
        { label: "24" },
        { label: "25" },
        { label: "26" },
        { label: "27" },
        { label: "28" },
        { label: "29" },
        { label: "30" },
        { label: "31" },
        { label: "32" },
        { label: "33" },
        { label: "34" },
        { label: "35" },
        { label: "36" },
        { label: "37" },
        { label: "38" },
        { label: "39" },
        { label: "40" },
        { label: "41" },
        { label: "42" },
        { label: "43" },
        { label: "44" },
        { label: "45" },
        { label: "46" },
        { label: "47" },
        { label: "48" },
        { label: "49" },
        { label: "50" },
        { label: "51" },
        { label: "52" }
    ],
    innerTypeMessurements: [
        { label: "ONESIZE" },
        { label: "PACK" },
        { label: "28A" },
        { label: "28AA" },
        { label: "28B" },
        { label: "28C" },
        { label: "28D" },
        { label: "28E" },
        { label: "30A" },
        { label: "30AA" },
        { label: "30B" },
        { label: "30C" },
        { label: "30D" },
        { label: "30DD" },
        { label: "30E" },
        { label: "30F" },
        { label: "30FF" },
        { label: "30G" },
        { label: "30J" },
        { label: "30JJ" },
        { label: "30K" },
        { label: "32A" },
        { label: "32AA" },
        { label: "32B" },
        { label: "32C" },
        { label: "32D" },
        { label: "32DD" },
        { label: "32DDD" },
        { label: "32E" },
        { label: "32F" },
        { label: "32FF" },
        { label: "32G" },
        { label: "32H" },
        { label: "32J" },
        { label: "32JJ" },
        { label: "32K" },
        { label: "32Z" },
        { label: "34A" },
        { label: "34B" },
        { label: "34C" },
        { label: "34D" },
        { label: "34DD" },
        { label: "34DDD" },
        { label: "34E" },
        { label: "34F" },
        { label: "34FF" },
        { label: "34G" },
        { label: "34GG" },
        { label: "34H" },
        { label: "34JJ" },
        { label: "34K" },
        { label: "34Z" },
        { label: "36A" },
        { label: "36B" },
        { label: "36C" },
        { label: "36D" },
        { label: "36DD" },
        { label: "36DDD" },
        { label: "36E" },
        { label: "36F" },
        { label: "36FF" },
        { label: "36G" },
        { label: "36GG" },
        { label: "36H" },
        { label: "36J" },
        { label: "36JJ" },
        { label: "36K" },
        { label: "36Z" },
        { label: "38A" },
        { label: "38B" },
        { label: "38C" },
        { label: "38D" },
        { label: "38DD" },
        { label: "38E" },
        { label: "38F" },
        { label: "38G" },
        { label: "38H" },
        { label: "38HH" },
        { label: "38J" },
        { label: "38JJ" },
        { label: "38K" },
        { label: "38Z" },
        { label: "39B" },
        { label: "40A" },
        { label: "40B" },
        { label: "40C" },
        { label: "40D" },
        { label: "40DD" },
        { label: "40E" },
        { label: "40F" },
        { label: "40FF" },
        { label: "40G" },
        { label: "40GG" },
        { label: "40H" },
        { label: "40HH" },
        { label: "40JJ" },
        { label: "40K" },
        { label: "40Z" },
        { label: "42A" },
        { label: "42B" },
        { label: "42C" },
        { label: "42D" },
        { label: "42DD" },
        { label: "42E" },
        { label: "42F" },
        { label: "42FF" },
        { label: "42G" },
        { label: "42GG" },
        { label: "42H" },
        { label: "42Z" },
        { label: "43B" },
        { label: "44A" },
        { label: "44B" },
        { label: "44C" },
        { label: "44D" },
        { label: "44DD" },
        { label: "44E" },
        { label: "44F" },
        { label: "44FF" },
        { label: "44G" },
        { label: "44GG" },
        { label: "44H" },
        { label: "44Z" },
        { label: "46A" },
        { label: "46B" },
        { label: "46C" },
        { label: "46D" },
        { label: "46DD" },
        { label: "46E" },
        { label: "46F" },
        { label: "46G" },
        { label: "48A" },
        { label: "48B" },
        { label: "48C" },
        { label: "48D" },
        { label: "48DD" },
        { label: "48E" },
        { label: "50A" },
        { label: "50B" },
        { label: "50C" },
        { label: "50D" },
        { label: "50DD" },
        { label: "50E" }

    ],

    sleevestype: [
        { label: "3/4 Sleeve" },
        { label: "Full Sleeve" },
        { label: "Half Sleeve" },
        { label: "Short Sleeve" },
        { label: "Sleeveless" }
    ],

    sleeve_styles: [
        { label: "Set-In Sleeve" },
        { label: "Raglan Sleeve" },
        { label: "Kimono Sleeve" },
        { label: "Bishop Sleeve" },
        { label: "Bell Sleeve" },
        { label: "Puff Sleeve" },
        { label: "Cold Shoulder Sleeve" },
        { label: "Off-the-Shoulder Sleeve" },
        { label: "Cape Sleeve" },

    ],
    top_shapes: [
        { label: "T-shirt" },
        { label: "Blouse" },
        { label: "Tank Top" },
        { label: "Crop Top" },
        { label: "Peplum Top" },
        { label: "Off-the-Shoulder Top" },
        { label: "Wrap Top" },
        { label: "Halter Top" },
        { label: "Bodysuit" },
        { label: "Tube Top" },
        { label: "High-Low Top" },
        { label: "Poncho Top" },
    ],
    top_types: [
        { label: "Indo-Western Suits" },
        { label: "Saree" },
        { label: "Salwar Kameez" },
        { label: "Anarkali Suit" },
        { label: "Patiala Suit" },
        { label: "Palazzo Suit" },
        { label: "Kurti with Cigarette Pants" },
        { label: "Cape top Saree" },
        { label: "Lehengas" },
        { label: "Kurti and Churidar" },
        { label: "Women’s Sherwani" },
        { label: "Indian Gowns" },
        { label: "Lancha" },
        { label: "Sharara and Gharara Suits" },
        { label: "Maxi Gown" },
        { label: "Straight" },
        { label: "Denim Jacket" }
    ],
    dress_designs: [
        { label: "A-line Dress" },
        { label: "Shift Dress" },
        { label: "Sheath Dress" },
        { label: "Wrap Dress" },
        { label: "Fit and Flare Dress" },
        { label: "Maxi Dress" },
        { label: "Bodycon Dress" },
        { label: "High-Low Dress" },
        { label: "Mermaid Dress" },
        { label: "Empire Waist Dress" },
        { label: "T-shirt Dress" },
        { label: "Pinafore Dress" }
    ],

    necktype: [
        { label: "Asymmetrical" },
        { label: "Boat neckline" },
        { label: "Collar" },
        { label: "Cowl neck" },
        { label: "Halter neckline" },
        { label: "Illusion" },
        { label: "Jewel" },
        { label: "Keyhole neckline" },
        { label: "Off-the-shoulder" },
        { label: "One shoulder" },
        { label: "Plunging" },
        { label: "Queen Anne" },
        { label: "Round neck or Crew neck" },
        { label: "Scalloped neckline" },
        { label: "Scoop neckline" },
        { label: "Spaghetti straps" },
        { label: "Split Crew neck" },
        { label: "Square neck" },
        { label: "Strapless" },
        { label: "Surplice" },
        { label: "Sweetheart neckline" },
        { label: "Tie Bow neckline" },
        { label: "Turtleneck (High Neckline)" },
        { label: "U-Shaped neckline" },
        { label: "V-neck" }
    ],
    pritsandPatterns: [
        { label: "Animal Print" },
        { label: "Applique" },
        { label: "Blocked Printed" },
        { label: "Checkered" },
        { label: "Color Block" },
        { label: "Digital Print" },
        { label: "Dyed" },
        { label: "Embellished" },
        { label: "Embroidered" },
        { label: "Floral Print" },
        { label: "Geometric Print" },
        { label: "Graphic Print" },
        { label: "Hand Painted" },
        { label: "Ombre" },
        { label: "Paisley" },
        { label: "Polka Print" },
        { label: "Printed" },
        { label: "Self Design" },
        { label: "Solid/Plain" },
        { label: "Striped" },
        { label: "Temple Border" },
        { label: "Woven" },
        { label: "Yoke Design" }
    ],
    slit_styles: [
        { label: "Side mini slit" },
        { label: "Multiple slit" },
        { label: "Back high slit" },
        { label: "Back mini slit" },
        { label: "Front mini slit" },
        { label: "Front high slit" },
        { label: "M-slit/Double slit" },
        { label: "Waist slit" },
        { label: "Ruche slit" },
        { label: "Flouncing slit" },
        { label: "Asymmetrical slit" },
        { label: "Symmetrical slit" },
        { label: "Lace up slit" },
        { label: "Tape slit" }
    ],
    length_styles: [
        { label: "ankle-length" },
        { label: "knee-length" },
        { label: "capri" },
        { label: "mid-calf" },
        { label: "stirrup" },
        { label: "athleisure" },
        { label: "faux-leather" },
        { label: "footed" },
        { label: "rugged" },
        { label: "woolen" },
        { label: "Regular" },
        { label: "Calf Length" }
    ],
    hemline_styles: [
        { label: "A-line Hem" },
        { label: "Asymmetrical Hemline" },
        { label: "Bubble Hem" },
        { label: "Fishtail hem" },
        { label: "Flared hemline" },
        { label: "Handkerchief hem" },
        { label: "High-Low hem" },
        { label: "Raw edge hem" },
        { label: "Ruffled hemline" },
        { label: "Sharkbite hem" },
        { label: "Shirt tail hemline" },
        { label: "Slant hemline" },
        { label: "Slit hemline" },
        { label: "Step hem" },
        { label: "Tiered Bottom Hem" }
    ],
    occasion_styles: [
        { label: "Casual" },
        { label: "Party & Festive" },
        { label: "Wedding" },
        { label: "Wedding & Festive" },
        { label: "Ethnic" }
    ],
    fabric_types: [
        { label: "Art Silk" },
        { label: "Brasso" },
        { label: "Chanderi" },
        { label: "Chiffon" },
        { label: "Cotton Blend" },
        { label: "Cotton Jute" },
        { label: "Cotton Linen" },
        { label: "Cotton Silk" },
        { label: "Crepe" },
        { label: "Dupion Silk" },
        { label: "Georgette" },
        { label: "Jacquard" },
        { label: "Jute Silk" },
        { label: "Lace" },
        { label: "Linen" },
        { label: "Lycra Blend" },
        { label: "Muslin" },
        { label: "Net" },
        { label: "Nylon" },
        { label: "Organza" },
        { label: "Polyester" },
        { label: "Pure Cotton" },
        { label: "Pure Silk" },
        { label: "Raw Silk" },
        { label: "Satin" },
        { label: "Silk Blend" },
        { label: "Supernet" },
        { label: "Tissue" },
        { label: "Tussar Silk" },
        { label: "Velvet" },
        { label: "Elastane" },
        { label: "Cotton Rayon" },
        { label: "Viscose Rayon" },
        { label: "Acrylic Blend" },
        { label: "Blended Cotton" },
        { label: "Khadi Cotton" },
        { label: "Khadi Silk" },
        { label: "Hemp" },
        { label: "Hosiery" },
        { label: "Modal" },
        { label: "Polycotton" },
        { label: "Pure Linen" },
        { label: "Pure Wool" },
        { label: "Rayon" },
        { label: "Wool" },
        { label: "Wool Blend" }
    ],
    saree_types: [
        { label: "Arani Pattu" },
        { label: "Assam Silk" },
        { label: "Baluchari" },
        { label: "Banarasi" },
        { label: "Bandhani" },
        { label: "Berhampuri" },
        { label: "Bhagalpuri" },
        { label: "Bollywood" },
        { label: "Chanderi" },
        { label: "Chettinadu" },
        { label: "Chinnalapattu" },
        { label: "Daily Wear" },
        { label: "Dharmavaram" },
        { label: "Dokhona" },
        { label: "Fashion" },
        { label: "Gadwal" },
        { label: "Garad" },
        { label: "Hand Batik" },
        { label: "Handloom" },
        { label: "Ikkat" },
        { label: "Ilkal" },
        { label: "Jamdani" },
        { label: "Kalamkari" },
        { label: "Kanjivaram" },
        { label: "Kantha" },
        { label: "Kasavu" },
        { label: "Konrad" },
        { label: "Kota Doria" },
        { label: "Kovai" },
        { label: "Leheria" },
        { label: "Lucknow Chikankari" },
        { label: "Madhubani" },
        { label: "Madurai" },
        { label: "Maheshwari" },
        { label: "Mangalagiri" },
        { label: "Manipuri" },
        { label: "Mekhela Chador" },
        { label: "Mundum Neriyathum" },
        { label: "Murshidabad" },
        { label: "Mysore" },
        { label: "Narayanpet" },
        { label: "Paithani" },
        { label: "Patola" },
        { label: "Pochampally" },
        { label: "Sambalpuri" },
        { label: "Tant" },
        { label: "Venkatagiri" },
        { label: "Regular" }
    ],
    saree_styles: [
        { label: "9 Yards Sari" },
        { label: "Half & Half Sari" },
        { label: "Lehenga Sari" },
        { label: "Ready to Wear" },
        { label: "Regular Sari" },
        { label: "Stitched Sari" }
    ],
    blouse_styles: [
        { label: "Blouse Piece" },
        { label: "Unstitched" }
    ],
    saree_border_styles: [
        { label: "Embellished" },
        { label: "Temple Saree Border" },
        { label: "Contrast Saree Border" },
        { label: "Mirror work Saree Border" },
        { label: "Scallop Saree Border" },
        { label: "Check Saree Border" },
        { label: "Velvet Saree Border" },
        { label: "Floral Saree Border" },
        { label: "Taping" }
    ],
    embellishment_types: [
        { label: "lace" },
        { label: "stone" },
        { label: "sequins" },
        { label: "beads" },
        { label: "ribbon" },
        { label: "metallic trims" },
        { label: "Gotta Patti" }
    ],
    fabric_care_instructions: [
        { fabric: "Acetate", label: "Hand Wash and Air Dry." },
        { fabric: "Cotton", label: "Hot Water and Warm Tumble Dry." },
        { fabric: "Linen", label: "Cool Water and Air Dry." },
        { fabric: "Polyester", label: "Cool Wash and Tumble Dry or Air Dry." },
        { fabric: "Silk", label: "Hand Wash/Cool and Cool Tumble Dry or Air Dry." },
        { fabric: "Wool", label: "Hand Wash and Air Dry." }
    ],
    kurti_styles: [
        { label: "Anarkali Kurti" },
        { label: "Straight Cut Kurti" },
        { label: "A-Line Kurti" },
        { label: "Lace Kurti" },
        { label: "Asymmetrical Kurti" },
        { label: "Kaftan Style Kurti" },
        { label: "Shirt Collar Kurti" },
        { label: "Overlay Kurti" },
        { label: "Jacket Style Kurti" },
        { label: "Cape Style Kurti" },
        { label: "Indo-Western Kurti" },
        { label: "Floor Length/Gown Style Kurti" },
        { label: "Color Block Kurti" },
        { label: "Printed Kurti" },
        { label: "Denim Kurti" },
        { label: "Chikankari Kurti" },
        { label: "Angrakha Kurti" },
        { label: "Dhoti Style Kurti" },
        { label: "Layered Kurti" },
        { label: "Flared Kurti" },
        { label: "Tie Dye Kurti" },
        { label: "Bandhani Kurti" },
        { label: "Kashmiri Kurti" },
        { label: "Gathered Kurti" },
        { label: "Peplum Kurti" },
        { label: "Tiered Kurti" },
        { label: "Boat Neck Kurti" },
        { label: "Embroidered Kurta" },
        { label: "Ikat Kurti" },
        { label: "Short Kurtis" },
        { label: "Cowel Neck Kurti" }
    ],
    bottom_styles_with_kurti: [
        { label: "Patiala Pants" },
        { label: "Palazzo or Parallel Pants" },
        { label: "Churidar Bottoms" },
        { label: "Straight Pants" },
        { label: "Sharara Pants" },
        { label: "Salwar" },
        { label: "Dhoti Pants" },
        { label: "Cigarette Pants" },
        { label: "Flared Skirt" }
    ],
    stitching_options: [
        { label: "Stitched" },
        { label: "Unstitched" }
    ],
    Dupattaavailable: [
        { label: "With Dupatta" },
        { label: "Without Dupatta" }
    ],
    Blouseavailable: [
        { label: "With Blouse" },
        { label: "Without Blouse" }
    ],

    fastening_types: [
        { label: "buttons" },
        { label: "snap fasteners" },
        { label: "zippers" },
        { label: "toggle fasteners" },
        { label: "loop closures" }
    ],
    wave_patterns: [
        { label: "Horizontal Waves" },
        { label: "Vertical Waves" },
        { label: "Diagonal Waves" },
        { label: "Chevron Waves" },
        { label: "Curly Waves" },
        { label: "Abstract Waves" },
        { label: "Ocean Waves" },
        { label: "Ripple Waves" },
        { label: "Geometric Waves" },
        { label: "Floral Waves" },
        { label: "Tribal Waves" },
        { label: "Gradient Waves" },
    ],
    wave_types: [
        { label: "Sine Wave" },
        { label: "Sinusoidal Wave" },
        { label: "Square Wave" },
        { label: "Sawtooth Wave" },
        { label: "Triangle Wave" },
        { label: "Pulse Wave" },
        { label: "Cosine Wave" },
        { label: "Pulse Train" },
        { label: "Square Pulse Train" },
        { label: "Damped Wave" },
        { label: "Standing Wave" },
    ],
    pattern_coverage: [
        { label: "All-Over Print" },
        { label: "Placement Print" },
        { label: "Border Print" },
        { label: "Scattered Print" },
        { label: "Stripe Print" },
        { label: "Geometric Print" },
        { label: "Floral Print" },
        { label: "Animal Print" },
        { label: "Abstract Print" },
        { label: "Tribal Print" },
        { label: "Polka Dot Print" },
        { label: "Check Print" },
    ],
    no_of_item: [
        { label: "1" },
        { label: "2" },
        { label: "3" },
        { label: "4" },
        { label: "5" },
        { label: "6" },
        { label: "7" },
        { label: "8" },
        { label: "9" },
        { label: "10" },
        { label: "11" },
        { label: "12" },
        { label: "13" },
        { label: "14" },
        { label: "15" },
    ],
    transparency_levels: [
        { label: "Opaque" },
        { label: "Semi-Transparent" },
        { label: "Sheer" },
        { label: "Mesh" },
        { label: "Lace" },
        { label: "Chiffon" },
        { label: "Organza" },
        { label: "Tulle" },
        { label: "Voile" },
        { label: "Gauze" },
        { label: "Burnout" },
        { label: "Netting" },
    ],
    main_trends: [
        { label: "Bohemian" },
        { label: "Minimalist" },
        { label: "Athleisure" },
        { label: "Vintage-Inspired" },
        { label: "Eclectic" },
        { label: "Feminine" },
        { label: "Streetwear" },
        { label: "Sustainable Fashion" },
        { label: "Modern Classic" },
        { label: "Avant-Garde" },
        { label: "Global-Inspired" },
        { label: "Techwear" },
    ],
};

const MessurentsSlice = createSlice({
    name: "Messurents",
    initialState,
});

export const {
} = MessurentsSlice.actions;
export default MessurentsSlice.reducer;