import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import {
  brandPost,
  brandImages,
  validatebrandSlugUrl,
  removeBrandImages,
} from "../../../redux/brand/brandSlice";
import { useToasts } from "react-toast-notifications";

const AddBrand = () => {
  const { desktopimage, imageLoading, mobileimage } = useSelector(
    (store) => store.brand
  );
  const { superCatTotal } = useSelector((store) => store.superCategory);

  const { addToast } = useToasts();

  const [brand, setBrand] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [show, setShow] = useState(Boolean(1));
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");

  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [supcategroyError, setsupcategroyError] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [imageError, setimageError] = useState("");
  useEffect(() => {
    if (buttonPress === true) {
      if (supercatid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (checkslugUrl === "") {
        setSlugUrlError("Required");
      } else {
        setSlugUrlError("");
      }
      if (mobileimage === "" || desktopimage === "") {
        setimageError("Required");
      } else {
        setimageError("");
      }
    }
  }, [buttonPress, supercatid, checkslugUrl, mobileimage, desktopimage]);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setBrand(value);
    setCheckSlugUrl("");
    const brandSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(brandSlug);

    if (brandSlug !== "") {
      let responce = await dispatch(validatebrandSlugUrl(brandSlug));
      if (responce.payload.success) {
        setError("Brand alredy exist");
        setErrorcolor("red");
      } else if (brandSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (brandSlug.length >= 3) {
        setErrorcolor(" ");
        setError("");
        setCheckSlugUrl(brandSlug);
      }
    }
  };
  const dispatch = useDispatch();

  const selectsuperCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercatid(value);
    setSupercat(text);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);

    if (supercatid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (checkslugUrl === "") {
      setSlugUrlError("Required");
    } else {
      setSlugUrlError("");
    }
    if (mobileimage === "" || desktopimage === "") {
      setimageError("Required");
    } else {
      setimageError("");
    }
    if (
      checkslugUrl !== "" &&
      supercatid !== "" &&
      desktopimage !== "" &&
      mobileimage !== ""
    ) {
      const formData = {
        name: brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase(),
        slugUrl: checkslugUrl,
        desktopImage: desktopimage,
        mobileImage: mobileimage,
        superCategory: supercat,
        superCategoryId: supercatid,
        show: show,
      };
      const data = await dispatch(brandPost(formData));
      if (data.payload.success) {
        addToast("Brand Added", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
          placement: "bottom-right",
          style: { zIndex: 99999 },
        });
        setbuttonPress(false);
        setBrand("");
        setSlugUrl("");
        setSupercatid("");
        setCheckSlugUrl("");
        setsupcategroyError("");
        setSlugUrlError("");
        setimageError("");
        dispatch(removeBrandImages());
      } else {
        setbuttonLoading(false);
      }
    } else {
      setbuttonLoading(false);
    }
  };

  const imageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(brandImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Add Brand</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Brand</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectsuperCategory(e);
                        }}
                        value={superCatTotal._id}
                        name={superCatTotal.name}
                        className={supcategroyError !== "" ? "error_class" : ""}
                      >
                        <option hidden selected>
                          {"Select Super Category"}
                        </option>
                        {superCatTotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            selected={supercatid === data._id}
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={brand}
                        onChange={(e) => verifyslugurl(e)}
                        className={slugUrlError !== "" ? "error_class" : ""}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label>Brand Thumbnail Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={imageChange}
                        className={imageError !== "" ? "error_class" : ""}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 480px * 360px
                      </p>
                      <div>
                        {imageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={desktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col className="d-flex ">
                      <div className="me-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="show"
                          checked={show === true}
                          onChange={(e) => {
                            setShow(e.target.checked);
                          }}
                        />
                        <Form.Label>Show</Form.Label>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {buttonLoading ? (
                    <button className="btn btn-primary">Add Brand</button>
                  ) : (
                    <button className="btn btn-primary" type="submit">
                      Add Brand
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddBrand;
