import React from "react";
import ClothingSupercat from "./Clothing/ClothingSupercat";
import BeautySupercat from "./Beauty/BeautySupercat";
import ElectronicsSupercat from "./Electronics/ElectronicsSupercat";
import GrocerySupercat from "./Grocery/GrocerySupercat";
import MedicienSupercat from "./Medicines/MedicienSupercat";
import ClothingsSuperCategory from "./Clothing/ClothingsSuperCategory";

const MainSupercatProduct = ({
  supercategory,
  category,
  subcategory,
  categoryTag,
  buttonPress,
  getPropDetails,
  subcategoryid,
  categoryid,
  supercategoryid,
  categoryTagid,
  resetield
}) => {
  const renderSwitch = (param) => {
    switch (param) {
      case "Clothing": {
        return (
          <>
            {/* <ClothingSupercat
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
              buttonPress={buttonPress}
              getPropDetails={getPropDetails}
            /> */}
            <ClothingsSuperCategory
              supercategory={supercategory}
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
              buttonPress={buttonPress}
              getPropDetails={getPropDetails}
              subcategoryid={subcategoryid}
              categoryid={categoryid}
              supercategoryid={supercategoryid}
              categoryTagid={categoryTagid}
              resetield={resetield}
            />
          </>
        );
      }
      case "Beauty": {
        return (
          <>
            <BeautySupercat
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
            />
          </>
        );
      }
      case "Electronics": {
        return (
          <>
            <ElectronicsSupercat
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
            />
          </>
        );
      }
      case "Grocery": {
        return (
          <>
            <GrocerySupercat
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
            />
          </>
        );
      }
      case "Medicines": {
        return (
          <>
            <MedicienSupercat
              category={category}
              subcategory={subcategory}
              categoryTag={categoryTag}
            />
          </>
        );
      }

      default:
        return <>{/* <ClothingSupercat /> */}</>;
    }
  };
  return <>{renderSwitch(supercategory)}</>;
};

export default MainSupercatProduct;
