import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineRemove } from "react-icons/md";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { FaInfoCircle } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import { Tooltip as ReactTooltip } from "react-tooltip";
import colorNamer from "color-namer";
import {
  PostField,
  getOneFieldStatus,
} from "../../../redux/fieldStatus/FieldStatusSlice";

const AddFieldsStatus = () => {
  const { superCatShow } = useSelector((store) => store.superCategory);
  const { categoryShow } = useSelector((store) => store.category);
  const { subcategoryShow } = useSelector((store) => store.subCategories);
  const { brandShow } = useSelector((store) => store.brand);
  const { fieldShow } = useSelector((store) => store.field);
  const { categoryTagShow, categoryTagTotal } = useSelector(
    (store) => store.categorytag
  );

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [catbysuper, setCatbysuper] = useState([]);
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [brand, setBrand] = useState("");
  const [categoryTagsShow, setCategoryTagsShow] = useState([]);
  const [categoryTag, setCategoryTag] = useState("");
  const [categoryTagid, setCategoryTagid] = useState("");
  const [fieldBySupcatShow, setfieldBySupcatShow] = useState([]);
  const [selectedfield, setselectedfield] = useState([]);
  const [distinctfield, setdistinctfield] = useState([]);

  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [supcategroyError, setsupcategroyError] = useState("");
  const [categroyError, setcategroyError] = useState("");
  const [subcategroyError, setSubcategroyError] = useState("");
  const [categoryTagError, setcategoryTagError] = useState("");
  const [fieldError, setfieldError] = useState("");

  useEffect(() => {
    if (fieldShow.length > 0) {
      const distinctNames = [...new Set(fieldShow.map((item) => item.name))];
      setdistinctfield(distinctNames);
      // const notMatched = array1.filter(item => !array2.includes(item));
    }
  }, [fieldShow]);

  useEffect(() => {
    if (categoryTagid) {
      const getdata = async () => {
        const formData = {
          superCategoryId: supercategoryid,
          categoryId: categoryid,
          SubCategoryId: subcategoryid,
          CategoryTagId: categoryTagid,
        };

        const data = await dispatch(getOneFieldStatus(formData));
        if (data.payload.success === true) {
          const resp = data.payload.field;
          const filterShow = resp.selectedFields.filter(
            (data) => data.status === true
          );
          const distinctNames = [
            ...new Set(filterShow.map((item) => item.field_Name)),
          ];
          setselectedfield(distinctNames);
        } else {
          setselectedfield([]);
        }
      };
      getdata();
    }
  }, [subcategoryid, categoryid, supercategoryid, categoryTagid]);

  useEffect(() => {
    if (superCatShow.length === 1) {
      setSupercategory(superCatShow[0].name);
      setSupercategoryid(superCatShow[0]._id);
    }
    if (categoryShow.length === 1) {
      setCategory(categoryShow[0].name);
      setCategoryid(categoryShow[0]._id);
    }
    if (subcategoryShow.length === 1) {
      setSubcategory(subcategoryShow[0].name);
      setSubcategoryid(subcategoryShow[0]._id);
    }
  }, [superCatShow, categoryShow, subcategoryShow]);

  const handleFielSelectPress = (evt) => {
    if (selectedfield.includes(evt.target.value)) {
      setselectedfield(
        selectedfield.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setselectedfield((selectedfield) => [...selectedfield, evt.target.value]);
    }
  };
  const handleFielSelectHandlePress = (e, evt) => {
    if (selectedfield.includes(evt)) {
      setselectedfield(
        selectedfield.filter((e) => {
          return e !== evt;
        })
      );
    } else {
      setselectedfield((selectedfield) => [...selectedfield, evt]);
    }
  };

  useEffect(() => {
    if (buttonPress === true) {
      if (supercategoryid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (categoryid === "") {
        setcategroyError("Required");
      } else {
        setcategroyError("");
      }
      if (subcategoryid === "") {
        setSubcategroyError("Required");
      } else {
        setSubcategroyError("");
      }
      if (categoryTagid === "") {
        setcategoryTagError("Required");
      } else {
        setcategoryTagError("");
      }
      if (selectedfield.length === 0) {
        setfieldError("Plesae select at least one field");
      } else {
        setfieldError("");
      }
    }
  }, [
    buttonPress,
    subcategoryid,
    categoryid,
    supercategoryid,
    categoryTagid,
    selectedfield,
  ]);

  // useEffect(() => {
  //   if (selectedfield.length > 0) {
  //     let fieldarray = [];
  //     let finalfieldarray = [];
  //     for (let i = 0; i < selectedfield.length; i++) {
  //       let element = selectedfield[i];

  //       let obje = { [element]: "", field_Name: element, status: true };
  //       fieldarray = [...fieldarray, obje];
  //     }
  //     fieldarray = fieldarray;
  //     let unselectedData = [];
  //     const notMatched = distinctfield.filter(
  //       (item) => !selectedfield.includes(item)
  //     );
  //     for (let j = 0; j < notMatched.length; j++) {
  //       let element = notMatched[j];
  //       let obje = { [element]: "", field_Name: element, status: false };
  //       unselectedData = [...unselectedData, obje];
  //     }
  //     unselectedData = unselectedData;

  //     finalfieldarray = [...fieldarray, ...unselectedData];
  //   }
  // }, [selectedfield, distinctfield]);

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercategoryid(value);
    setSupercategory(text);
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const catbysupercat = categoryShow.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
    const fildbysupercat = fieldShow.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setfieldBySupcatShow(fildbysupercat);
  }, [supercategoryid, categoryShow, fieldShow]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };
  useEffect(() => {
    const subcatbycat = subcategoryShow.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategoryShow]);

  const selectCategoryTag = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryTagid(value);
    setCategoryTag(text);
  };

  useEffect(() => {
    const catTagbySubcat = categoryTagShow.filter(
      (subcat) => subcat.SubCategoryId === subcategoryid
    );
    setCategoryTagsShow(catTagbySubcat);
  }, [subcategoryid, categoryTagShow]);

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);
    if (supercategoryid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (categoryid === "") {
      setcategroyError("Required");
    } else {
      setcategroyError("");
    }
    if (subcategoryid === "") {
      setSubcategroyError("Required");
    } else {
      setSubcategroyError("");
    }
    if (categoryTagid === "") {
      setcategoryTagError("Required");
    } else {
      setcategoryTagError("");
    }

    if (selectedfield.length === 0) {
      setfieldError("Plesae select at least one field");
    } else {
      setfieldError("");
    }

    if (
      supercategoryid !== "" &&
      categoryid !== "" &&
      subcategoryid !== "" &&
      categoryTagid !== "" &&
      selectedfield.length > 0
    ) {
      let fieldarray = [];
      let finalfieldarray = [];
      for (let i = 0; i < selectedfield.length; i++) {
        let element = selectedfield[i];

        let obje = { [element]: "", field_Name: element, status: true };
        fieldarray = [...fieldarray, obje];
      }
      fieldarray = fieldarray;
      let unselectedData = [];
      const notMatched = distinctfield.filter(
        (item) => !selectedfield.includes(item)
      );
      for (let j = 0; j < notMatched.length; j++) {
        let element = notMatched[j];
        let obje = { [element]: "", field_Name: element, status: false };
        unselectedData = [...unselectedData, obje];
      }
      unselectedData = unselectedData;

      finalfieldarray = [...fieldarray, ...unselectedData];

      const formData = {
        superCategoryId: supercategoryid,
        superCategory: supercategory,
        category: category,
        categoryId: categoryid,
        SubCategory: subcategory,
        SubCategoryId: subcategoryid,
        CategoryTag: categoryTag,
        CategoryTagId: categoryTagid,
        selectedFields: finalfieldarray,
      };
      const data = await dispatch(PostField(formData));
      if (data.payload.success) {
        addToast("Fiel Status Added", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
          placement: "bottom-right",
          style: { zIndex: 99999 },
        });
        setbuttonPress(false);
        setbuttonLoading(false);
        setselectedfield([]);

        if (superCatShow.length > 1) {
          setSupercategory("");
          setSupercategoryid("");
        }
        if (categoryShow.length > 1) {
          setCategory("");
          setCategoryid("");
        }
        if (subcategoryShow.length > 1) {
          setSubcategory("");
          setSubcategoryid("");
        }
        if (categoryTagShow.length > 1) {
          setCategoryTag("");
          setCategoryTagid("");
        }
      } else {
        setbuttonLoading(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      setbuttonLoading(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Field Status </h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    {superCatShow.length > 1 && (
                      <Col md>
                        <Form.Label htmlFor="disabledTextInput">
                          Super Category<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Floating label select example"
                          onChange={(e) => {
                            selectsupCategory(e);
                          }}
                          value={superCatShow._id}
                          name={superCatShow.name}
                          className={
                            supcategroyError !== "" ? "error_class" : ""
                          }
                        >
                          <option hidden selected>
                            {"Select Super Category"}
                          </option>
                          {superCatShow.map((data) => (
                            <option
                              key={data._id}
                              value={data._id}
                              name={data.name}
                              selected={supercategoryid === data._id}
                            >
                              {data.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    )}
                    {categoryShow.length > 1 && (
                      <Col md>
                        <Form.Label htmlFor="disabledTextInput">
                          Category<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Floating label select example"
                          onChange={(e) => {
                            selectCategory(e);
                          }}
                          value={catbysuper._id}
                          name={catbysuper.name}
                          className={categroyError !== "" ? "error_class" : ""}
                        >
                          <option hidden selected>
                            {"Select Category"}
                          </option>
                          {catbysuper.map((data) => (
                            <option
                              key={data._id}
                              value={data._id}
                              name={data.name}
                              selected={categoryid === data._id}
                            >
                              {data.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    )}

                    {subcategoryShow.length > 1 && (
                      <Col md>
                        <Form.Label htmlFor="disabledTextInput">
                          Sub Category<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Floating label select example"
                          onChange={(e) => {
                            selectSubCategory(e);
                          }}
                          value={subCat._id}
                          name={subCat.name}
                          className={
                            subcategroyError !== "" ? "error_class" : ""
                          }
                        >
                          <option hidden selected>
                            {"Select Sub Category"}
                          </option>
                          {subCat.map((data) => (
                            <option
                              key={data._id}
                              value={data._id}
                              name={data.name}
                              selected={subcategoryid === data._id}
                            >
                              {data.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    )}
                    {categoryTagShow.length > 1 && (
                      <Col md>
                        <Form.Label htmlFor="disabledTextInput">
                          Tag<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Floating label select example"
                          onChange={(e) => {
                            selectCategoryTag(e);
                          }}
                          value={categoryTagsShow._id}
                          name={categoryTagsShow.name}
                          className={
                            categoryTagError !== "" ? "error_class" : ""
                          }
                        >
                          <option hidden selected>
                            {"Select Sub Category"}
                          </option>
                          {categoryTagsShow.map((data) => (
                            <option
                              key={data._id}
                              value={data._id}
                              name={data.name}
                              selected={categoryTagid === data._id}
                            >
                              {data.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    )}
                  </Row>
                  <Row className="g-2  m-2">
                    <p style={{ color: "red" }}> {fieldError}</p>
                  </Row>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      marginTop: "0.2vh",
                    }}
                  >
                    {fieldBySupcatShow.map((data, index) => (
                      <div
                        className="me-4 d-flex"
                        style={{ width: "22%" }}
                        key={index}
                      >
                        <input
                          type="checkbox"
                          className="me-1"
                          value={data.name}
                          checked={selectedfield.includes(data.name)}
                          onChange={(e) => handleFielSelectPress(e, data.name)}
                          style={{ cursor: "pointer" }}
                        />
                        <Form.Label
                          className="mt-2 ml-2"
                          onClick={(e) =>
                            handleFielSelectHandlePress(e, data.name)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {index + 1}) {data.name}
                        </Form.Label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="card-footer">
                  {buttonLoading ? (
                    <button className="btn btn-danger">
                      Field Status adding...
                    </button>
                  ) : (
                    <button className="btn btn-danger" type="submit">
                      Add Field Status
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddFieldsStatus;
